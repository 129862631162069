<template>
  <v-tooltip
    v-model="showTooltip"
    :top="tooltipPosition === 'top' || (!tooltipPosition && tooltip)"
    :bottom="tooltipPosition === 'bottom'"
    :left="tooltipPosition === 'left'"
    :right="tooltipPosition === 'right'"
    v-bind:disabled="(!tooltip || tooltip === '') && !tooltipPosition"
  >
    <template v-slot:activator="{ on: tooltipOn }">
      <v-autocomplete
        v-if="autocomplete || autocomplete === ''"
        v-on="tooltipOn"
        v-bind:append-icon="appendIcon"
        v-bind:append-outer-icon="appendOuterIcon"
        v-bind:attach="attach"
        v-bind:autofocus="autofocus"
        v-bind:background-color="backgroundColor"
        v-bind:cache-items="cacheItems"
        v-bind:chips="chips"
        v-bind:clear-icon="clearIcon"
        v-bind:clearable="clearable"
        v-bind:color="color"
        v-bind:counter="counter"
        v-bind:counter-value="counterValue"
        v-bind:dark="dark"
        v-bind:deletable-chips="deletableChips"
        v-bind:dense="isDense"
        v-bind:disable-lookup="disableLookup"
        v-bind:disabled="disabled"
        v-bind:eager="eager"
        v-bind:error-count="errorCount"
        v-bind:error-messages="errors"
        v-bind:filled="isFilled"
        v-bind:filter="filter"
        v-bind:flat="flat"
        v-bind:full-width="fullWidth"
        v-bind:height="height"
        v-bind:hide-details="hideDetails"
        v-bind:hide-selected="hideSelected"
        v-bind:hint="hint"
        v-bind:id="id"
        v-bind:item-color="itemColor"
        v-bind:item-disabled="itemDisabled"
        v-bind:item-text="itemText"
        v-bind:item-value="itemValue"
        v-bind:items="items"
        v-bind:label="caption"
        v-bind:light="light"
        v-bind:loader-height="loaderHeight"
        v-bind:loading="loading"
        v-bind:menu-props="menuProps"
        v-bind:messages="messages"
        v-bind:multiple="multiple"
        v-bind:no-data-text="noDataText"
        v-bind:open-on-clear="openOnClear"
        v-bind:outlined="isOutline"
        v-bind:persistent-hint="persistentHint"
        v-bind:placeholder="placeholder"
        v-bind:prefix="prefix"
        v-bind:prepend-icon="prependIcon"
        v-bind:prepend-inner-icon="prependInnerIcon"
        v-bind:readonly="readonly"
        v-bind:return-object="returnObject"
        v-bind:reverse="isReverse"
        v-bind:rounded="isRounded"
        v-bind:rules="rules"
        v-bind:shaped="shaped"
        v-bind:single-line="singleLine"
        v-bind:solo="isSolo"
        v-bind:solo-inverted="soloInverted"
        v-bind:success="success"
        v-bind:success-messages="successMessages"
        v-bind:suffix="suffix"
        v-bind:type="type"
        v-bind:validate-on-blur="validateOnBlur"
        v-model="variable"
        :style="customStyle"
        v-bind:value-comparator="valueComparator"
        @focus="onfocus"
        @blur="onblur"
        @click:append-outer="clickAppendOuter"
        @click:append="clickAppend"
        @click:prepend-outer="clickPrependOuter"
        @click:prepend="clickPrepend"
        @keyup.enter="doAction"
      >
        <template v-slot:selection="{ item }">
          <slot
            v-if="$scopedSlots.selection"
            name="selection"
            v-bind:item="item"
          ></slot>
          <template v-else-if="codeMode === 'selection' || codeMode === 'both'">
            <template v-if="codeStyle === 'chip'">
              <v-chip
                label
                small
                class="mr-3"
                :color="item.color || (itemColor || 'primary')"
              >
                {{ item[itemValue] }}
              </v-chip>
            </template>
            <template v-else-if="codeStyle === 'icon'">
              <v-icon
                small
                class="mr-3"
                :color="item.color || (itemColor || 'primary')"
              >
                {{ item.icon }}
              </v-icon>
            </template>
            <template v-else>
              <span class="mr-2">{{ item[itemValue] }}</span>
            </template>
            <span
              class="mr-4"
              v-if="!hideLabel || hideLabel === 'none'"
            >{{ $app.i18n(item.label) }}</span>
          </template>
          <template v-else>
            {{ $app.i18n(item.label || item[itemText]) }}
          </template>
        </template>

        <template v-slot:item="{ item }">
          <slot
            v-if="$scopedSlots.item"
            name="item"
            v-bind:item="item"
          ></slot>
          <template v-else-if="codeMode === 'list' || codeMode === 'both'">
            <template v-if="codeStyle === 'chip'">
              <v-chip
                label
                class="mr-3"
                :color="item.color || (itemColor || 'primary')"
              >
                {{ item[itemValue] }}
              </v-chip>
            </template>
            <template v-else-if="codeStyle === 'icon'">
              <v-icon
                small
                class="mr-3"
                :color="item.color || (itemColor || 'primary')"
              >
                {{ item.icon }}
              </v-icon>
            </template>
            <template v-else>
              <span class="mr-2">{{ item[itemValue] }}</span>
            </template>
            {{ hideLabel === 'list' || hideLabel === 'both' ? '' : $app.i18n(item.label) }}
          </template>
          <template v-else>
            <span style="width: 100%; text-overflow: ellipsis; white-space: nowrap; overflow: hidden;">{{ $app.i18n(item.label || item[itemText]) }}</span>
          </template>
        </template>
      </v-autocomplete>
      <v-select
        v-else
        v-on="tooltipOn"
        v-bind:append-icon="appendIcon"
        v-bind:append-outer-icon="appendOuterIcon"
        v-bind:attach="attach"
        v-bind:autofocus="autofocus"
        v-bind:background-color="backgroundColor"
        v-bind:cache-items="cacheItems"
        v-bind:chips="chips"
        v-bind:clear-icon="clearIcon"
        v-bind:clearable="clearable"
        v-bind:color="color"
        v-bind:counter="counter"
        v-bind:counter-value="counterValue"
        v-bind:dark="dark"
        v-bind:deletable-chips="deletableChips"
        v-bind:dense="isDense"
        v-bind:disable-lookup="disableLookup"
        v-bind:disabled="disabled"
        v-bind:eager="eager"
        v-bind:error-count="errorCount"
        v-bind:error-messages="errors"
        v-bind:filled="isFilled"
        v-bind:filter="filter"
        v-bind:flat="flat"
        v-bind:full-width="fullWidth"
        v-bind:height="height"
        v-bind:hide-details="hideDetails"
        v-bind:hide-selected="hideSelected"
        v-bind:hint="hint"
        v-bind:id="id"
        v-bind:item-color="itemColor"
        v-bind:item-disabled="itemDisabled"
        v-bind:item-text="itemText"
        v-bind:item-value="itemValue"
        v-bind:items="items"
        v-bind:label="caption"
        v-bind:light="light"
        v-bind:loader-height="loaderHeight"
        v-bind:loading="loading"
        v-bind:menu-props="menuProps"
        v-bind:messages="messages"
        v-bind:multiple="multiple"
        v-bind:no-data-text="noDataText"
        v-bind:open-on-clear="openOnClear"
        v-bind:outlined="isOutline"
        v-bind:persistent-hint="persistentHint"
        v-bind:placeholder="placeholder"
        v-bind:prefix="prefix"
        v-bind:prepend-icon="prependIcon"
        v-bind:prepend-inner-icon="prependInnerIcon"
        v-bind:readonly="readonly"
        v-bind:return-object="returnObject"
        v-bind:reverse="isReverse"
        v-bind:rounded="isRounded"
        v-bind:rules="rules"
        v-bind:shaped="shaped"
        v-bind:single-line="singleLine"
        v-bind:solo="isSolo"
        v-bind:solo-inverted="soloInverted"
        v-bind:success="success"
        v-bind:success-messages="successMessages"
        v-bind:suffix="suffix"
        v-bind:type="type"
        v-bind:validate-on-blur="validateOnBlur"
        v-model="variable"
        :style="customStyle"
        v-bind:value-comparator="valueComparator"
        @focus="onfocus"
        @blur="onblur"
        @click:append-outer="clickAppendOuter"
        @click:append="clickAppend"
        @click:prepend-outer="clickPrependOuter"
        @click:prepend="clickPrepend"
        @keyup.enter="doAction"
      >
        <template v-slot:selection="{ item }">
          <slot
            v-if="$scopedSlots.selection"
            name="selection"
            v-bind:item="item"
          ></slot>
          <template v-else-if="codeMode === 'selection' || codeMode === 'both'">
            <div style="width: 100%; text-overflow: ellipsis; white-space: nowrap; overflow: hidden;">
              <template v-if="codeStyle === 'chip'">
                <v-chip
                  label
                  small
                  class="mr-3"
                  :color="item.color || (itemColor || 'primary')"
                >
                  {{ item[itemValue] }}
                </v-chip>
              </template>
              <template v-else-if="codeStyle === 'icon'">
                <v-icon
                  small
                  class="mr-3"
                  :color="item.color || (itemColor || 'primary')"
                >
                  {{ item.icon }}
                </v-icon>
              </template>
              <template v-else>
                <span class="mr-2">{{ item[itemValue] }}</span>
              </template>
              <span
                class="mr-4"
                v-if="!hideLabel || hideLabel === 'none'"
              >{{ $app.i18n(item.label) }}</span>
            </div>
          </template>
          <template v-else>
            <div style="width: 100%; text-overflow: ellipsis; white-space: nowrap; overflow: hidden;">
              <v-chip
                v-if="selection === 'chip' || selection === 'outline'"
                label
                :outlined="selection === 'outline'"
                :color="item.color || (itemColor || 'primary')"
              >
                {{ $app.i18n(item.label || item[itemText]) }}
              </v-chip>
              <span v-else>{{ $app.i18n(item.label || item[itemText]) }}</span>
            </div>
          </template>
        </template>

        <template v-slot:item="{ item }">
          <slot
            v-if="$scopedSlots.item"
            name="item"
            v-bind:item="item"
          ></slot>
          <template v-else-if="codeMode === 'list' || codeMode === 'both'">
            <template v-if="codeStyle === 'chip'">
              <v-chip
                label
                class="mr-3"
                :color="item.color || (itemColor || 'primary')"
              >
                {{ item[itemValue] }}
              </v-chip>
            </template>
            <template v-else-if="codeStyle === 'icon'">
              <v-icon
                small
                class="mr-3"
                :color="item.color || (itemColor || 'primary')"
              >
                {{ item.icon }}
              </v-icon>
            </template>
            <template v-else>
              <span class="mr-2">{{ item.code }}</span>
            </template>
            {{ hideLabel === 'list' || hideLabel === 'both' ? '' : $app.i18n(item.label) }}
          </template>
          <template v-else>
            {{ $app.i18n(item.label || item[itemText]) }}
          </template>
        </template>
      </v-select>
    </template>
  </v-tooltip>
</template>

<script>

// import $app from '@/plugins/modules'

export default {
  props: [
    'autocomplete',
    'errorText',
    // Application style
    'inputStyle',
    'name',
    'required',

    // Show codes mode
    // list - show additional info in list
    // selection - show additional info in selection
    // both - show additional info in list and selection
    // none - no additional info (default)
    'codeMode',

    // Show codes style
    // chip - show additional info as chip
    // icon - show additional info as icon
    // text - show additional info as text (default)
    'codeStyle',

    // Show selection label style
    // chip - show additional info as chip
    // outline - show additional info as outlined chip
    // text - show additional info as text (default)
    'selection',

    // Hide label in
    // list - hide in list
    // selection - hide in selection
    // both - hide in list and selection
    // none - no hide label (default)
    'hideLabel',

    'tooltip',
    'tooltipPosition',
    'tooltipFocused',
    'validator',
    // ---------------------------------------------------------- Parent
    'appendIcon',
    'appendOuterIcon',
    'attach',
    'autofocus',
    'backgroundColor',
    'cacheItems',
    'chips',
    'clearIcon',
    'clearable',
    'color',
    'counter',
    'counterValue',
    'dark',
    'deletableChips',
    'dense',
    'disableLookup',
    'disabled',
    'eager',
    'error',
    'errorCount',
    'errorMessages',
    'filled',
    'filter',
    'flat',
    'fullWidth',
    'height',
    'hideDetails',
    'hideSelected',
    'hint',
    'id',
    'itemColor',
    'itemDisabled',
    'itemText',
    'itemValue',
    'items',
    'label',
    'light',
    'loaderHeight',
    'loading',
    'maxWidth',
    'menuProps',
    'messages',
    'multiple',
    'noDataText',
    'openOnClear',
    'outlined',
    'persistentHint',
    'placeholder',
    'prefix',
    'prependIcon',
    'prependInnerIcon',
    'readonly',
    'returnObject',
    'reverse',
    'rounded',
    'rules',
    'shaped',
    'singleLine',
    'solo',
    'soloInverted',
    'success',
    'successMessages',
    'suffix',
    'type',
    'validateOnBlur',
    'value',
    'valueComparator'
  ],
  data: () => ({
    internal_error: undefined,
    showTooltip: false,
    focused: false
  }),
  watch: {
    variable: {
      immediate: true,
      handler: function (value) {
        this.validate(value)
      }
    }
  },

  methods: {
    doAction: function (event) {
      this.$emit('action', event)
    },

    clickAppendOuter: function (event) {
      this.$emit('click:append-outer', event)
    },

    clickAppend: function (event) {
      this.$emit('click:append', event)
    },

    clickPrependOuter: function (event) {
      this.$emit('click:prepend-outer', event)
    },

    clickPrepend: function (event) {
      this.$emit('click:prepend', event)
    },

    onfocus: function (event) {
      this.focused = true
    },

    onblur: function (event) {
      this.focused = false
    },

    isEmpty: function (value) {
      return value === undefined || value === null || value === ''
    },

    validate: function (value) {
      if (this.isEmpty(this.value)) {
        this.internal_error =
          this.required === undefined || this.required === false
            ? undefined
            : this.$app.i18n('system.global.RequiredField')
      } else {
        this.internal_error = undefined
      }

      if (this.validator && this.name) {
        this.$set(this.validator, this.name, this.internal_error === undefined)
      }
    }
  },

  computed: {
    isReverse: function () {
      return this.reverse === true || this.reverse === '';
    },

    isOutline: function () {
      return this.inputStyle && (this.outlined === undefined || this.outlined === null)
        ? this.inputStyle.inputOutline
        : this.outlined;
    },

    isDense: function () {
      return this.inputStyle && (this.dense === undefined || this.dense === null)
        ? this.inputStyle.inputDense
        : this.dense;
    },

    isSolo: function () {
      return this.inputStyle && (this.solo === undefined || this.solo === null)
        ? this.inputStyle.inputSolo
        : this.solo;
    },

    isFilled: function () {
      return this.inputStyle && (this.filled === undefined || this.filled === null)
        ? this.inputStyle.inputFilled
        : this.filled;
    },

    isRounded: function () {
      return this.inputStyle && (this.rounded === undefined || this.rounded === null)
        ? this.inputStyle.inputRounded
        : this.rounded;
    },

    customStyle: function () {
      let style = '';
      if (this.maxWidth && this.maxWidth !== '') {
        style += ' max-width: ' + this.maxWidth + '; '
      }
      return style;
    },

    errors: function () {
      if (
        this.error !== undefined &&
        this.error !== null &&
        this.error !== ''
      ) {
        return [this.error]
      } else if (this.internal_error !== undefined) {
        return [this.internal_error]
      } else {
        return undefined
      }
    },

    caption: function () {
      if (this.required || this.required === '') {
        return this.$app.i18n(this.label) + ' *'
      } else {
        return this.$app.i18n(this.label)
      }
    },

    variable: {
      get: function () {
        return this.value
      },

      set: function (value) {
        this.$emit('input', value)
        this.$emit('changed', value)
      }
    }
  },

  beforeMount: function () {
    this.validate(this.variable)
  },

  beforeUpdate: function () {
    this.validate(this.variable)
  }
}
</script>

<style>
.v-text-field--outlined legend {
  height: 0px !important;
  max-width: 0px !important;
}

.v-input--selection-controls.no-margin {
  margin-top: 2px !important;
}

.v-text-field--outlined .v-label--active {
  top: 6px !important;
  transition: none !important;
}

.theme--dark.v-label {
  color: rgba(190, 208, 240, 0.6) !important;
}

.theme--light.v-label {
  color: #9aa1b7 !important;
}

.v-text-field--outlined .v-label {
  top: -24px !important;
  font-size: 14px;
  font-weight: lighter;
  transform: none !important;
  transition: none !important;
}
</style>
